<template>
  <div class="addAndEditNews">
    <!-- 输入框 -->
    <el-form
      ref="editForm"
      class="elFrom"
      label-width="80px"
      :model="editForm"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="editForm.title"
          size="small"
          auto-complete="off"
          placeholder="请输入标题"
        />
      </el-form-item>
      <el-form-item label="副标题" prop="subTitle">
        <el-input
          v-model="editForm.subTitle"
          size="small"
          auto-complete="off"
          placeholder="请输入副标题"
        />
      </el-form-item>
      <!-- 排序 -->
      <el-form-item label="排序" prop="sortIndex">
        <el-input
          v-model="editForm.sortIndex"
          size="small"
          placeholder="请输入数字，数字越小越靠前"
        />
      </el-form-item>
      <el-form-item label="新闻类型" prop="newsType">
        <el-select v-model="editForm.newsType" placeholder="请选择新闻类型">
          <el-option
            v-for="item in newsType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="所需平台" prop="newsPlatList">
        <el-select v-model="editForm.newsPlatList" multiple placeholder="请选择新闻类型" @change="getNewsPlatList">
          <el-option label="所有" :value="1" />
          <el-option label="官网" :disabled="editForm.desabled" :value="2" />
          <el-option label="商城" :disabled="editForm.desabled" :value="3" />
        </el-select>
      </el-form-item>
      <!-- 缩略图 -->
      <el-form-item label="缩略图" prop="banner">
        <el-upload
          class="avatar-uploader"
          :action="$baseUpload"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img
            v-if="editForm.banner"
            :src="$fileUrl + editForm.banner"
            class="avatar"
          >
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
        <p class="tip">
          注：仅支持bmp、jpg、png、gif格式，图片大小不超过2M。
        </p>
      </el-form-item>
      <!-- 是否置顶 -->
      <el-form-item label="是否置顶" prop="isTop">
        <el-radio v-model="editForm.isTop" :label="1">
          是
        </el-radio>
        <el-radio v-model="editForm.isTop" :label="0">
          否
        </el-radio>
      </el-form-item>
      <!-- 是否上架 -->
      <el-form-item label="是否上架" prop="state">
        <el-radio v-model="editForm.state" :label="1">
          是
        </el-radio>
        <el-radio v-model="editForm.state" :label="0">
          否
        </el-radio>
      </el-form-item>
      <!-- 富文本 -->
      <el-form-item label="内容" prop="content">
        <TinymceEditor :introduction-content="editForm.content" @getIntroductionContent="getEditorContent" />
      </el-form-item>
      <el-form-item>
        <router-link
          :to="{ path: '/websiteManagement/newsList' }"
        >
          <el-button size="small">
            取消
          </el-button>
        </router-link>
        <el-button
          size="small"
          :loading="loading"
          type="primary"
          @click="saveAddAndEdit('editForm')"
        >
          确定
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAndEditNews, getNewsByDetail } from '@/api/websiteManagement'
import { newsType } from '@/systemConfiguration/index'
import TinymceEditor from '@/components/TinymceEditor'
export default {
  components: { TinymceEditor },
  data() {
    var validate = (rule, value, callback) => {
      if (this.editForm.newsPlatList.length === 0) {
        callback(new Error('请选择所需平台'))
        return
      }
      callback()
    }
    return {
      newsType,
      title: '添加新闻',
      headers: {
        token: localStorage.getItem('logintoken')
      },
      editForm: {
        content: '',
        newsPlatList: []
      },
      loading: false,
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        newsType: [
          { required: true, message: '请选择新闻类型', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写文章内容', trigger: 'blur' }
        ],
        newsPlatList: [{ required: true, validator: validate, trigger: 'change' }],
        banner: [{ required: true, message: '请上传图片', trigger: 'change' }],
        state: [
          { required: true, message: '请选择是否上架', trigger: 'change' }
        ],
        isTop: [
          { required: true, message: '请选择是否置顶', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      getNewsByDetail(this.$route.query.id, res => {
        this.editForm = { ...res.data }
        this.editForm.newsType = parseInt(this.editForm.newsType)
      })
    }
  },
  methods: {
    // 所需平台修改
    getNewsPlatList() {
      this.editForm.newsPlatList
      if (this.editForm.newsPlatList.includes(1)) {
        this.editForm.newsPlatList = [1]
        this.editForm.desabled = true
        return
      }
      this.editForm.desabled = false
    },
    handleAvatarSuccess(res) {
      this.editForm.banner = res.data
      this.$forceUpdate()
    },
    getEditorContent(value) {
      this.editForm.content = value
    },
    saveAddAndEdit(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          // 请求方法
          addAndEditNews(
            this.editForm,
            () => {
              this.loading = false
              this.$message.success('成功！')
              setTimeout(() => {
                this.$router.push({ path: '/websiteManagement/newsList' })
              }, 1000)
            },
            () => {
              this.loading = false
            }
          )
        }
      })
    }
  }
}
</script>

<style lang="scss">
.addAndEditNews {
  background-color: #fff;
  padding: 24px;
  .elFrom {
    margin-top: 20px;
  }
  .tip {
    color: red;
    font-size: 14px;
    margin: 0;
    margin-top: 6px;
    line-height: 1;
  }
  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 178px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader:hover {
    border-color: #409eff;
  }
  .el-select {
    width: 100%;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
